import { ReactElement } from "react";
import { Button, Divider, Group, Stack, Stepper as MantineStepper } from "@mantine/core";
import { Step } from "./types";

type StepperProps = {
    currentStep: number;
    steps: Array<Step>;
    onPrevStep: () => void;
    onNextStep: () => void;
};

export const Stepper = ({
    currentStep,
    steps,
    onPrevStep,
    onNextStep,
}: StepperProps): ReactElement => (
    <MantineStepper
        active={currentStep}
        breakpoint="sm"
        allowNextStepsSelect={false}
        color="flexidaoGreen"
        orientation="horizontal"
    >
        {steps.map(
            (
                { label, description, icon, content, isLoading, nextButtonProps, prevButtonProps },
                index,
            ) => (
                <MantineStepper.Step
                    key={index}
                    label={label}
                    description={description}
                    icon={icon}
                    loading={isLoading}
                    allowStepClick={false}
                    allowStepSelect={false}
                    px="24px"
                    pt="16px"
                >
                    <Stack spacing={0}>
                        <Divider />

                        {content}

                        <Divider />

                        <Group position="right" py="8px" px="16px">
                            {!prevButtonProps?.hidden && (
                                <Button
                                    variant="outline"
                                    onClick={onPrevStep}
                                    color="flexidaoRed"
                                    disabled={prevButtonProps?.disabled}
                                    uppercase
                                >
                                    {prevButtonProps?.label ?? "Previous step"}
                                </Button>
                            )}

                            {!nextButtonProps?.hidden && (
                                <Button
                                    onClick={onNextStep}
                                    color="flexidaoGreen"
                                    disabled={nextButtonProps?.disabled}
                                    uppercase
                                >
                                    {nextButtonProps?.label ?? "Next step"}
                                </Button>
                            )}
                        </Group>
                    </Stack>
                </MantineStepper.Step>
            ),
        )}
    </MantineStepper>
);
