import {
    ReportHubDto,
    reportHubPaths,
    TrackingInstrumentType,
    validateStrictDecoder,
} from "@flexidao/dto";
import { dateDecoder, uuidDecoder } from "@flexidao/helpers";
import * as D from "schemawax";
import { InferResponse } from "../api-client";
import { useTypeDecoder } from "./eac-data";
import {
    contractTypeDecoder,
    energySourceDecoder,
    globalRegionIdDecoder,
    sourcingMethodDecoder,
    trackingInstrumentTypeDecoder,
} from "./misc";

export const re100ContributionDecoder = validateStrictDecoder<ReportHubDto.RE100Contribution>()(
    D.literalUnion(...Object.values(ReportHubDto.RE100Contribution)),
);

export const getEacContractTrackingKpisDecoder: D.Decoder<ReportHubDto.ContractTrackingKpis> =
    D.object({
        required: {
            interval_Wh: D.number,
            intervalPpa_Wh: D.number,
            intervalGreenTariff_Wh: D.number,
            billing_Wh: D.number,
            billingPpa_Wh: D.number,
            billingGreenTariff_Wh: D.number,
            eacsReceived_Wh: D.number,
            eacsAllocated_Wh: D.number,
            eacsPendingAllocation_Wh: D.number,
        },
    });

export const getContractTrackingOverviewPpaRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewPpaRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingOverviewPpaRow>()(
        D.object({
            required: {
                contractName: D.string,
                contractId: D.string,
                contractType: contractTypeDecoder,
                interval_Wh: D.nullable(D.number),
                invoiced_Wh: D.nullable(D.number),
                allocatedTrackingInstruments_Wh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
                allocatedVsIntervalPercentage: D.nullable(D.number),
                allocatedVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getContractTrackingOverviewPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewPpa> =
    validateStrictDecoder<ReportHubDto.ContractTrackingOverviewPpa>()(
        D.object({
            required: {
                rows: D.array(getContractTrackingOverviewPpaRowDecoder),
            },
        }),
    );

export const getEacContractTrackingOverviewGreenTariffRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewGreenTariffRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingOverviewGreenTariffRow>()(
        D.object({
            required: {
                contractName: D.string,
                contractId: D.string,
                interval_Wh: D.nullable(D.number),
                invoiced_Wh: D.nullable(D.number),
                allocatedTrackingInstruments_Wh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
                allocatedVsIntervalPercentage: D.nullable(D.number),
                allocatedVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getEacContractTrackingOverviewGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewGreenTariff> =
    validateStrictDecoder<ReportHubDto.ContractTrackingOverviewGreenTariff>()(
        D.object({
            required: {
                rows: D.array(getEacContractTrackingOverviewGreenTariffRowDecoder),
            },
        }),
    );

export const getEacContractTrackingOverviewUnbundledRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewUnbundledRow> =
    D.object({
        required: {
            contractName: D.string,
            contractId: D.string,
            counterpart: D.string,
            deliveryDate: dateDecoder,
            volume_MWh: D.number,
            currencyUnit: D.string,
            price_currencyUnitPerMWh: D.number,
            contractAmount_currencyUnit: D.number,
            allocatedTrackingInstruments_Wh: D.nullable(D.number),
            missingTrackingInstruments_Wh: D.number,
        },
    });
export const getEacContractTrackingOverviewUnbundledDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewUnbundled> =
    D.object({
        required: {
            rows: D.array(getEacContractTrackingOverviewUnbundledRowDecoder),
        },
    });

export const region: D.Decoder<ReportHubDto.Region> = D.object({
    required: {
        regionId: D.string,
        name: D.string,
    },
});
export const contractOptionPpa: D.Decoder<ReportHubDto.ContractOptionPpa> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
        productionSitesIds: D.array(D.string),
    },
});
export const contractOption: D.Decoder<ReportHubDto.ContractOption> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
    },
});

export const getRegions: D.Decoder<ReportHubDto.GetRegions> = D.array(region);
export const getContractsPpa: D.Decoder<ReportHubDto.GetContractsPpa> = D.array(contractOptionPpa);
export const getContractsGreenTariff: D.Decoder<ReportHubDto.GetContractsGreenTariff> =
    D.array(contractOption);

export const getContractTrackingMonthlyPpaRowDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyPpaRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyPpaRow>()(
        D.object({
            required: {
                year: D.number,
                month: D.number,
                interval_Wh: D.nullable(D.number),
                invoiced_Wh: D.nullable(D.number),
                allocatedTrackingInstruments_Wh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
                allocatedVsIntervalPercentage: D.nullable(D.number),
                allocatedVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getContractTrackingMonthlyPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyPpa> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyPpa>()(
        D.array(getContractTrackingMonthlyPpaRowDecoder),
    );

export const getContractTrackingMonthlyGreenTariffRowDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyGreenTariffRow> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyGreenTariffRow>()(
        D.object({
            required: {
                year: D.number,
                month: D.number,
                interval_Wh: D.nullable(D.number),
                invoiced_Wh: D.nullable(D.number),
                intervalVsInvoicedPercentage: D.nullable(D.number),
            },
        }),
    );

export const getContractTrackingMonthlyGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyGreenTariff> =
    validateStrictDecoder<ReportHubDto.ContractTrackingMonthlyGreenTariff>()(
        D.array(getContractTrackingMonthlyGreenTariffRowDecoder),
    );

const registryDecoder: D.Decoder<ReportHubDto.Registry> = D.object({
    required: {
        registryId: D.string,
        name: D.string,
    },
});

const eacAllocationDecoder: D.Decoder<ReportHubDto.Allocation> = D.object({
    required: {
        trackingInstrumentType: D.literal(TrackingInstrumentType.EAC),
        eacSchemeId: D.string,
        transactionFromName: D.nullable(D.string),
        productionSiteName: D.string,
        productionSiteId: D.string,
        consumptionSiteId: D.nullable(D.string),
        consumptionPeriodStart: dateDecoder,
        consumptionPeriodEnd: dateDecoder,
        transactionId: D.string,
        uploadDate: dateDecoder,
        allocatedTo: D.nullable(D.string),
        allocatedToName: D.nullable(D.string),
        isUnbundled: D.boolean,
        consumptionOrganizationName: D.nullable(D.string),
        volumeWh: D.number,
        energySourceId: D.nullable(energySourceDecoder),
        productionCountryId: D.nullable(D.string),
        consumptionTimezone: D.string,
        registry: registryDecoder,
    },
});

const attestationAllocationDecoder: D.Decoder<ReportHubDto.Allocation> = D.object({
    required: {
        trackingInstrumentType: D.literal(TrackingInstrumentType.Attestation),
        eacSchemeId: D.nullable(D.string),
        transactionFromName: D.nullable(D.string),
        productionSiteName: D.nullable(D.string),
        productionSiteId: D.nullable(D.string),
        consumptionSiteId: D.nullable(D.string),
        consumptionPeriodStart: dateDecoder,
        consumptionPeriodEnd: dateDecoder,
        transactionId: D.string,
        uploadDate: dateDecoder,
        allocatedTo: D.nullable(D.string),
        allocatedToName: D.nullable(D.string),
        isUnbundled: D.boolean,
        consumptionOrganizationName: D.nullable(D.string),
        volumeWh: D.number,
        energySourceId: D.nullable(energySourceDecoder),
        productionCountryId: D.nullable(D.string),
        consumptionTimezone: D.string,
        registry: D.null,
    },
});

const allocationDecoder: D.Decoder<ReportHubDto.Allocation> = D.oneOf(
    eacAllocationDecoder,
    attestationAllocationDecoder,
);

export const getAllocationsByTenantIdResponseDecoder: D.Decoder<ReportHubDto.GetAllocationsByTenantIdResponse> =
    D.object({
        required: {
            allocations: D.array(allocationDecoder),
            totalAllocations: D.number,
        },
    });

export const allocationPayloadDecoder: D.Decoder<PostAllocationsByTenantIdResponse[number]> =
    D.object({
        required: {
            trackingInstrumentId: D.string,
            trackingInstrumentType: trackingInstrumentTypeDecoder,
            allocationTarget: D.oneOf(uuidDecoder, D.literal("unbundled")),
        },
    });

type PostAllocationsByTenantIdResponse = InferResponse<
    reportHubPaths,
    "/{tenantId}/allocations",
    "post",
    201
>;

export const postAllocationsByTenantIdResponseDecoder: D.Decoder<PostAllocationsByTenantIdResponse> =
    D.array(allocationPayloadDecoder);

export const localContractDecoder: D.Decoder<ReportHubDto.LocalContract> = D.object({
    required: {
        contractId: D.string,
        sourcingMethod: sourcingMethodDecoder,
        reportingYear: D.nullable(D.number),
    },
});

export const eacCodeDecoder: D.Decoder<ReportHubDto.EacCode> = D.object({
    required: {
        tenantId: D.string,
        eacCodeId: D.number,
        eacCode: D.string,
        registryId: D.string,
        useType: useTypeDecoder,
    },
});

/* TODO: Components and GetComponent should be on dtos */
type Components = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schemas: Record<string, any>;
};
export type GetComponent<C extends Components, Name extends keyof C["schemas"]> = C extends {
    schemas: {
        // eslint-disable-next-line no-unused-vars
        [N in Name]: infer Q;
    };
}
    ? Q
    : never;

export const countryDecoder: D.Decoder<ReportHubDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
        passiveProcurement: D.boolean,
    },
});

export const marketDecoder: D.Decoder<ReportHubDto.Market> = D.object({
    required: {
        marketId: D.string,
        name: D.string,
        description: D.string,
    },
});

export const annualRegionEmissionFactorDecoder: D.Decoder<ReportHubDto.AnnualRegionEmissionFactor> =
    validateStrictDecoder<ReportHubDto.AnnualRegionEmissionFactor>()(
        D.object({
            required: {
                regionId: D.string,
                reportingYear: D.number,
                marketBasedEmissions_g_Wh: D.number,
                locationBasedEmissions_g_Wh: D.number,
            },
        }),
    );

const assignmentDetailDecoder = validateStrictDecoder<ReportHubDto.AssignmentDetail>()(
    D.object({
        required: {
            trackingInstrumentType: D.nullable(trackingInstrumentTypeDecoder),
            eacCancellationId: D.nullable(D.string),
            attestationId: D.nullable(D.string),
            assignedWh: D.number,
            contributionToRe100Wh: D.number,
            productionPeriodStart: D.nullable(dateDecoder),
            productionPeriodEnd: D.nullable(dateDecoder),
            productionTimezone: D.nullable(D.string),
            eacSchemeId: D.nullable(D.string),
            productionCountryId: D.nullable(D.string),
            productionCountryName: D.nullable(D.string),
            energySourceId: D.nullable(energySourceDecoder),
            productionSiteCommissioningDate: D.nullable(dateDecoder),
            sourcingMethod: sourcingMethodDecoder,
            contractId: D.nullable(D.string),
            contractStartTimeLocal: D.nullable(dateDecoder),
            documentId: D.nullable(D.number),
        },
    }),
);

export const countryAssignmentResponseDecoder =
    validateStrictDecoder<ReportHubDto.CountryAssignmentsResponse>()(
        D.object({
            required: {
                totalCount: D.number,
                assignments: D.array(assignmentDetailDecoder),
            },
        }),
    );

export const postAssignmentResponse = D.string;
export const countryAssignmentKpiDecoder: D.Decoder<ReportHubDto.CountryAssignmentKpis> =
    validateStrictDecoder<ReportHubDto.CountryAssignmentKpis>()(
        D.object({
            required: {
                countryId: D.string,
                countryName: D.string,
                consumptionWh: D.number,
                globalRegionId: globalRegionIdDecoder,
                assignedWh: D.number,
                locationBasedEmissionsT: D.number,
                marketBasedEmissionsT: D.number,
                passiveProcurement: D.boolean,
                regionIds: D.array(D.string),
            },
        }),
    );

export const reportingKpisDecoder: D.Decoder<ReportHubDto.GetReportingKpisResponse> = D.object({
    required: {
        totals: D.object({
            required: {
                consumptionWh: D.number,
                assignedWh: D.number,
                locationBasedEmissionsT: D.number,
                marketBasedEmissionsT: D.number,
            },
        }),
        byCountry: D.array(countryAssignmentKpiDecoder),
    },
});

export const contractOptionDecoder: D.Decoder<ReportHubDto.ContractOption> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
    },
});

export const contractOptionsArrayDecoder: D.Decoder<Array<ReportHubDto.ContractOption>> =
    D.array(contractOptionDecoder);

export const allocationFiltersDecoder: D.Decoder<ReportHubDto.AllocationFilters> = D.object({
    required: {
        transactionIds: D.array(D.string),
        productionSiteNames: D.array(D.nullable(D.string)),
        allocatedToNames: D.array(D.nullable(D.string)),
        productionCountries: D.array(D.nullable(D.string)),
        energySources: D.array(D.nullable(energySourceDecoder)),
        registries: D.array(D.nullable(registryDecoder)),
    },
});

export const getGlobalOverviewKpisDecoder: D.Decoder<ReportHubDto.GlobalOverviewKpis> = D.object({
    required: {
        consumption: D.object({
            required: {
                totalWh: D.number,
                trackingInstrumentCoverage: D.number,
            },
        }),
        volumes: D.object({
            required: {
                totalWh: D.number,
                ppaWh: D.number,
                greenTariffWh: D.number,
                unbundledWh: D.number,
            },
        }),
        trackingInstruments: D.object({
            required: {
                totalWh: D.number,
                eacCancelledWh: D.number,
                eacTotalWh: D.number,
                attestationTotalWh: D.number,
            },
        }),
    },
});
export const getGlobalOverviewV2KpisDecoder: D.Decoder<ReportHubDto.GlobalOverviewV2Kpis> =
    D.object({
        required: {
            consumptionWh: D.number,
            notCoveredConsumptionWh: D.number,
            coveredByTrackingInstrumentsWh: D.number,
            purchasedTrackingInstruments: D.object({
                required: {
                    totalWh: D.number,
                    physicalContractsWh: D.number,
                    financialContractsWh: D.number,
                },
            }),
        },
    });

const getGlobalOverviewCountryCoverageDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountryCoverageDetail> =
    D.object({
        required: {
            tenantId: D.string,
            countryId: D.string,
            countryName: D.string,
            consumptionWh: D.number,
            purchasedVolumeWh: D.number,
            trackingInstrumentWh: D.number,
            coveredShare: D.number,
            uncoveredWh: D.number,
        },
    });

export const getGlobalOverviewCountriesCoverageDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountriesCoverage> =
    D.object({
        required: {
            countriesCoverage: D.array(getGlobalOverviewCountryCoverageDetailDecoder),
        },
    });

const getGlobalOverviewV2CountryPerformanceDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewV2CountryDetail> =
    D.object({
        required: {
            countryName: D.string,
            consumptionWh: D.number,
            notCoveredConsumptionWh: D.number,
            coveredByTrackingInstrumentsWh: D.number,
        },
    });
export const getGlobalOverviewCountriesPerformanceDecoder: D.Decoder<ReportHubDto.GlobalOverviewV2CountriesPerformance> =
    D.object({
        required: {
            countriesPerformance: D.array(getGlobalOverviewV2CountryPerformanceDetailDecoder),
        },
    });
const getGlobalOverviewV2GroupPerformanceDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewV2GroupDetail> =
    D.object({
        required: {
            groupName: D.string,
            consumptionWh: D.number,
            notCoveredConsumptionWh: D.number,
            coveredByTrackingInstrumentsWh: D.number,
        },
    });
export const getGlobalOverviewGroupsPerformanceDecoder: D.Decoder<ReportHubDto.GlobalOverviewV2GroupsPerformance> =
    D.object({
        required: {
            groupsPerformance: D.array(getGlobalOverviewV2GroupPerformanceDetailDecoder),
        },
    });

export const getCountriesDecoder: D.Decoder<ReportHubDto.GetCountries> = D.array(countryDecoder);

const reportingPeriodDecoder: D.Decoder<ReportHubDto.ReportingPeriod> = D.object({
    required: {
        tenantId: D.string,
        year: D.number,
        startReportingPeriodLocal: dateDecoder,
        endReportingPeriodLocal: dateDecoder,
    },
});
export const getReportingPeriodsDecoder: D.Decoder<ReportHubDto.GetReportingPeriods> =
    D.array(reportingPeriodDecoder);

export const getContractTrackingKpisPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisPpa> =
    validateStrictDecoder<ReportHubDto.ContractTrackingKpisPpa>()(
        D.object({
            required: {
                interval_Wh: D.number,
                invoiced_Wh: D.number,
                allocatedTrackingInstruments_Wh: D.number,
            },
        }),
    );
export const getContractTrackingKpisGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisGreenTariff> =
    validateStrictDecoder<ReportHubDto.ContractTrackingKpisGreenTariff>()(
        D.object({
            required: {
                interval_Wh: D.number,
                invoiced_Wh: D.number,
                allocatedTrackingInstruments_Wh: D.number,
            },
        }),
    );

export const getContractTrackingKpisUnbundledDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisUnbundled> =
    D.object({
        required: {
            volume_MWh: D.number,
            allocatedTrackingInstruments_Wh: D.number,
            missingTrackingInstruments_Wh: D.number,
        },
    });
