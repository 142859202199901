import { notifications } from "@mantine/notifications";
import { Input, stringify } from "csv-stringify/browser/esm";

/**
 * Generates a CSV from the given data, errors logged to console and notification shown
 * @param data Input data to create the CSV
 * @param filename the filename for the download
 */
export const generateCsv = async (data: Input): Promise<Blob> => {
    try {
        const csvData: string = await new Promise((resolve, reject) => {
            stringify(data, (err, output) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(output);
                }
            });
        });

        return new Blob([csvData], { type: "text/csv" });
    } catch (err) {
        console.error(err);
        notifications.show({ color: "flexidaoRed", message: "Error generating CSV file" });

        throw err;
    }
};
