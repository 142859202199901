/**
 * Save a file to the user's computer
 *
 * @param blob - The file to save
 * @param filename - The name of the file
 *
 */
export const saveFile = (blob: Blob, filename: string): void => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
};
