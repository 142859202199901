import { DateTime } from "luxon";
import { crash } from "./utils";

/**
 * This type is a string that represents a date in the format yyyy-mm.
 *
 * You might be tempted to use `${"1t" | "2"}${Digit}${Digit}${Digit}-${"01" | "02" | "03" | "04" | "05" | "06" | "07" | "08" | "09" | "10" | "11" | "12"}`
 * but it does not work well as the TypeScript compiler will output the 24 thousand combinations of
 * the string whenever it has to represent the type.
 */
export type YyyyMm =
    `${"1" | "2"}${number}-${"01" | "02" | "03" | "04" | "05" | "06" | "07" | "08" | "09" | "10" | "11" | "12"}`;

/**
 * This function validates a string to see if it is a valid yyyy-mm.
 * @param d {string}
 */
export const validateYyyyMm = (d: string): d is YyyyMm => {
    return DateTime.fromFormat(d, "yyyy-MM").isValid;
};

/**
 * This function is a shortcut for the yyyy-mm search param for the date picker.
 * @param d {string | null}
 */
export const validYyyyMmOrNull = (d: string | null): YyyyMm | null => {
    return d !== null && validateYyyyMm(d) ? d : null;
};

/**
 * This function is a shortcut for the yyyy-mm search param for the date picker.
 * @param d {string | null}
 */
export const validYyyyMmOrCrash = (d: string | null): YyyyMm => {
    return d !== null && validateYyyyMm(d) ? d : crash(`Invalid yyyy-mm: ${d}`);
};

/**
 * This function converts the date object to a search param for the month picker.
 *
 * @param date - The date object
 *
 * @returns The search param from the date object
 */
export const dateToYyyyMm = (date: Date): YyyyMm => {
    // eslint-disable-next-line no-magic-numbers
    return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, "0")}` as YyyyMm;
};

/**
 * This function converts the Yyyy-Mm search param for the date picker to date objects.
 *
 * @param yearMonth - The Yyyy-Mm search param for the date picker
 *
 * @returns The date object
 */
export const yyyyMmToDate = (yearMonth: YyyyMm): Date => {
    return new Date(`${yearMonth}-01T00:00:00.000Z`);
};
