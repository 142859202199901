/* eslint-disable no-magic-numbers */
import { Group as MantineGroup, Text } from "@mantine/core";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { Bar } from "@visx/shape";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { AxisBottom, AxisRight, TickFormatter } from "../_utils/axes";
import { AXIS_FONT_SIZE, getDefaultRightAxisTickLabelProps } from "../_utils/utils";

type PositiveNegativeBarChartTemplateProps<K extends Record<string, number | Date | null>> = {
    data: Array<K>;
    xScale: ScaleBand<number>;
    xMax: number;
    yScale: ScaleLinear<number, number, never>;
    yMax: number;
    height: number;
    upperHeight: number;
    fillPositive: string;
    fillNegative: string;
    axisColor: string;
    label: string;
    xNumTicks: number;
    xTickFormatter: TickFormatter;
    yTickFormatter: TickFormatter;
    getXValue: (d: K) => number;
    getYValue: (d: K) => number | null;
};

export const PositiveNegativeBarChartTemplate = <K extends Record<string, number | Date | null>>({
    data,
    xScale,
    xMax,
    yScale,
    yMax,
    height,
    upperHeight,
    fillPositive,
    fillNegative,
    axisColor,
    label,
    xNumTicks,
    xTickFormatter,
    yTickFormatter,
    getXValue,
    getYValue,
}: PositiveNegativeBarChartTemplateProps<K>): JSX.Element => {
    return (
        <>
            <ParentSize>
                {({ width }): JSX.Element => {
                    return (
                        <svg width={width} height={height}>
                            <Group className={"financial-performance-chart-bars"}>
                                <>
                                    {data.map((d: K) => {
                                        const value = getYValue(d);
                                        const fill =
                                            value && value > 0 ? fillPositive : fillNegative;
                                        const barWidth = xScale.bandwidth();
                                        const barHeight = Math.abs(
                                            upperHeight - (yScale(Math.abs(value ?? 0)) ?? 0),
                                        );

                                        const barX = xScale(getXValue(d));
                                        const barY =
                                            !value || value < 0
                                                ? upperHeight
                                                : upperHeight - barHeight;
                                        return (
                                            <Bar
                                                key={`bar-${barX}`}
                                                x={barX}
                                                y={barY}
                                                width={barWidth}
                                                height={barHeight}
                                                fill={fill}
                                            />
                                        );
                                    })}
                                </>
                            </Group>
                            <Group>
                                <>
                                    <Group className={"financial-performance-chart-axis-right"}>
                                        <AxisRight
                                            innerWidth={xMax}
                                            yScale={yScale}
                                            axisColor={axisColor}
                                            numTicks={5}
                                            tickFormat={yTickFormatter}
                                            hideTicks={false}
                                            hideZero={false}
                                            tickLabelProps={():
                                                | {
                                                      fill: string;
                                                      fontSize: number;
                                                      textAnchor: string;
                                                      dy: string;
                                                      dx: string;
                                                  }
                                                | {} => {
                                                return getDefaultRightAxisTickLabelProps(
                                                    axisColor,
                                                    AXIS_FONT_SIZE,
                                                );
                                            }}
                                        />
                                    </Group>
                                    <Group className={"financial-performance-chart-axis-bottom"}>
                                        <AxisBottom
                                            kind="band"
                                            top={yMax}
                                            xScale={xScale}
                                            axisColor={axisColor}
                                            numTicks={xNumTicks}
                                            textAnchor="middle"
                                            tickFormat={xTickFormatter}
                                        />
                                    </Group>
                                </>
                            </Group>
                        </svg>
                    );
                }}
            </ParentSize>
            <MantineGroup spacing="xs" className={"financial-performance-chart-legend"}>
                <svg width={12} height={12}>
                    <circle fill={fillPositive} cx="6" cy="6" r="6" />
                </svg>
                <Text fz="xs">Positive {label}</Text>
                <svg width={12} height={12}>
                    <circle fill={fillNegative} cx="6" cy="6" r="6" />
                </svg>
                <Text fz="xs">Negative {label}</Text>
            </MantineGroup>
        </>
    );
};
