import { GridEmissionsDto, gridEmissionsPaths } from "@flexidao/dto";
import { ApiClient } from "../api-client";
import { featureFlagDecoder, noContentDecoder } from "../decoders";
import {
    co2EmissionFactorDecoder,
    countriesWithZonesArrayDecoder,
    electricityMapDataResponseDecoder,
    getCo2EmissionFactorsDecoder,
    hourlyIntensitiesResponseDecoder,
    sourceArrayDecoder,
    zoneResponseDecoder,
    zonesEmissionsRatesResponseDecoder,
} from "../decoders/grid-emissions";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash, routeBlocker } from "../utils";

type GetCo2EmissionFactorsByTenantIdArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    page: number;
    search?: string;
};

export type GridEmissionsClient = {
    getPublicHourlyIntensities: (params: {
        zones: Array<string>;
        startTime: Date;
        endTime: Date;
        gridEmissionsKey: string;
    }) => ApiResponse<Record<string, Array<GridEmissionsDto.ZoneIntensityDto>>>;
    getPublicZones: (gridEmissionsKey: string) => ApiResponse<Array<GridEmissionsDto.ZoneDto>>;
    getPublicElectricityMapDataByZoneId: (
        zoneId: string,
        gridEmissionsKey: string,
    ) => ApiResponse<Array<GridEmissionsDto.ElectricityMapDataDto>>;
    getPublicYearlyEmissionRatesByYear: (
        year: number,
        zoneIds: Array<string>,
        gridEmissionsKey: string,
    ) => ApiResponse<Array<GridEmissionsDto.EmissionRate>>;
    getPublicSources: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<GridEmissionsDto.Source>>;
    // Not implemented from here on
    getPublicEnergySourceEmissionFactors: () => never;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<GridEmissionsDto.CountryWithZones>>;

    // CO2 Emission Factors
    getCo2EmissionFactorsByTenantId: (
        args: GetCo2EmissionFactorsByTenantIdArgs,
    ) => ApiResponse<GridEmissionsDto.GetCo2EmissionFactorsResponse>;
    getCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
    ) => ApiResponse<GridEmissionsDto.Co2EmissionFactor>;
    postCo2EmissionFactorsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: GridEmissionsDto.CreateCo2EmissionFactorPayload,
    ) => ApiResponse<GridEmissionsDto.Co2EmissionFactor>;
    putCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
        body: GridEmissionsDto.UpdateCo2EmissionFactorPayload,
    ) => ApiResponse<GridEmissionsDto.Co2EmissionFactor>;
    deleteCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
    ) => ApiResponse<undefined>;

    // Feature flags
    getPublicFeatureFlags: (getAccessToken: () => Promise<string>) => ApiResponse<Array<string>>;
} & ApiClient<gridEmissionsPaths>;

export type OverloadGridEmissionsClient = {
    getPublicHourlyIntensities: (params: {
        zones: Array<string>;
        startTime: Date;
        endTime: Date;
        gridEmissionsKey: string;
    }) => OverloadApiResponse<Record<string, Array<GridEmissionsDto.ZoneIntensityDto>>>;
    getPublicZones: (
        gridEmissionsKey: string,
    ) => OverloadApiResponse<Array<GridEmissionsDto.ZoneDto>>;
    getPublicElectricityMapDataByZoneId: (
        zoneId: string,
        gridEmissionsKey: string,
    ) => OverloadApiResponse<Array<GridEmissionsDto.ElectricityMapDataDto>>;
    getPublicYearlyEmissionRatesByYear: (
        year: number,
        zoneIds: Array<string>,
        gridEmissionsKey: string,
    ) => OverloadApiResponse<Array<GridEmissionsDto.EmissionRate>>;
    getPublicSources: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<GridEmissionsDto.Source>>;
    // Not implemented from here on
    getPublicEnergySourceEmissionFactors: () => never;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<GridEmissionsDto.CountryWithZones>>;

    // CO2 Emission Factors
    getCo2EmissionFactorsByTenantId: (
        args: GetCo2EmissionFactorsByTenantIdArgs,
    ) => OverloadApiResponse<GridEmissionsDto.GetCo2EmissionFactorsResponse>;
    getCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
    ) => OverloadApiResponse<GridEmissionsDto.Co2EmissionFactor>;
    postCo2EmissionFactorsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: GridEmissionsDto.CreateCo2EmissionFactorPayload,
    ) => OverloadApiResponse<GridEmissionsDto.Co2EmissionFactor>;
    putCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
        body: GridEmissionsDto.UpdateCo2EmissionFactorPayload,
    ) => OverloadApiResponse<GridEmissionsDto.Co2EmissionFactor>;
    deleteCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
    ) => OverloadApiResponse<undefined>;

    // Feature flags
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<string>>;
} & ApiClient<gridEmissionsPaths>;

export type ResultGridEmissionsClient = {
    getPublicHourlyIntensities: (params: {
        zones: Array<string>;
        startTime: Date;
        endTime: Date;
        gridEmissionsKey: string;
    }) => ApiResultResponse<Record<string, Array<GridEmissionsDto.ZoneIntensityDto>>>;
    getPublicZones: (
        gridEmissionsKey: string,
    ) => ApiResultResponse<Array<GridEmissionsDto.ZoneDto>>;
    getPublicElectricityMapDataByZoneId: (
        zoneId: string,
        gridEmissionsKey: string,
    ) => ApiResultResponse<Array<GridEmissionsDto.ElectricityMapDataDto>>;
    getPublicYearlyEmissionRatesByYear: (
        year: number,
        zoneIds: Array<string>,
        gridEmissionsKey: string,
    ) => ApiResultResponse<Array<GridEmissionsDto.EmissionRate>>;
    getPublicSources: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<GridEmissionsDto.Source>>;
    // Not implemented from here on
    getPublicEnergySourceEmissionFactors: () => never;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<GridEmissionsDto.CountryWithZones>>;

    // CO2 Emission Factors
    getCo2EmissionFactorsByTenantId: (
        args: GetCo2EmissionFactorsByTenantIdArgs,
    ) => ApiResultResponse<GridEmissionsDto.GetCo2EmissionFactorsResponse>;
    getCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
    ) => ApiResultResponse<GridEmissionsDto.Co2EmissionFactor>;
    postCo2EmissionFactorsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: GridEmissionsDto.CreateCo2EmissionFactorPayload,
    ) => ApiResultResponse<GridEmissionsDto.Co2EmissionFactor>;
    putCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
        body: GridEmissionsDto.UpdateCo2EmissionFactorPayload,
    ) => ApiResultResponse<GridEmissionsDto.Co2EmissionFactor>;
    deleteCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        co2EmissionFactorId: string,
    ) => ApiResultResponse<undefined>;

    // Feature flags
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<string>>;
} & ApiClient<gridEmissionsPaths>;

export function getGridEmissionsClient(config: Record<string, never>): GridEmissionsClient;
export function getGridEmissionsClient(config: { useResult: boolean }): ResultGridEmissionsClient;
export function getGridEmissionsClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): OverloadGridEmissionsClient {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        useMockData = process.env.MOCK_GRID_EMISSIONS === "true";

        if (!process.env.GRID_EMISSIONS_URL) {
            return crash("Missing GRID_EMISSIONS_URL environment variable.");
        } else {
            url = process.env.GRID_EMISSIONS_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildQueries({ ...config, useMockData: useMockData }, url);

    const { get, post, put, del } = buildQueries({ ...config, useMockData: useMockData }, url);

    return {
        getPublicHourlyIntensities: ({
            zones,
            startTime,
            endTime,
            gridEmissionsKey,
        }: {
            zones: Array<string>;
            startTime: Date;
            endTime: Date;
            gridEmissionsKey: string;
        }): OverloadApiResponse<Record<string, Array<GridEmissionsDto.ZoneIntensityDto>>> =>
            get({
                path: "/api/grid-emissions/public/hourly-intensities",
                decoder: hourlyIntensitiesResponseDecoder,
                params: {
                    zones,
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString(),
                },
                headers: {
                    "x-auth-key": gridEmissionsKey,
                },
            }),
        getPublicZones: (
            gridEmissionsKey: string,
        ): OverloadApiResponse<Array<GridEmissionsDto.ZoneDto>> =>
            get({
                path: `/api/grid-emissions/public/zones`,
                decoder: zoneResponseDecoder,
                headers: {
                    "x-auth-key": gridEmissionsKey,
                },
            }),
        getPublicElectricityMapDataByZoneId: (
            zoneId: string,
            gridEmissionsKey: string,
        ): OverloadApiResponse<Array<GridEmissionsDto.ElectricityMapDataDto>> =>
            get({
                path: `/api/grid-emissions/public/electricity-map-data/${zoneId}`,
                decoder: electricityMapDataResponseDecoder,
                headers: {
                    "x-auth-key": gridEmissionsKey,
                },
            }),
        getPublicYearlyEmissionRatesByYear: (
            year: number,
            zoneIds: Array<string>,
            gridEmissionsKey: string,
        ): OverloadApiResponse<Array<GridEmissionsDto.EmissionRate>> =>
            get({
                path: `/api/grid-emissions/public/yearly-emission-rates/${year}`,
                decoder: zonesEmissionsRatesResponseDecoder,
                params: {
                    zoneIds,
                },
                headers: {
                    "x-auth-key": gridEmissionsKey,
                },
            }),
        getPublicSources: async (
            getAccessToken: () => Promise<string>,
        ): OverloadApiResponse<Array<GridEmissionsDto.Source>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/grid-emissions/public/sources`,
                decoder: sourceArrayDecoder,
                params: {},
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Not implemented from here on
        getPublicEnergySourceEmissionFactors: (): never => {
            throw new Error("Not implemented");
        },

        getPublicCountries: async (
            getAccessToken,
        ): OverloadApiResponse<Array<GridEmissionsDto.CountryWithZones>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/grid-emissions/public/countries`,
                decoder: countriesWithZonesArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // CO2 Emission Factors
        getCo2EmissionFactorsByTenantId: async ({
            getAccessToken,
            tenantId,
            page,
            search,
        }: GetCo2EmissionFactorsByTenantIdArgs): OverloadApiResponse<GridEmissionsDto.GetCo2EmissionFactorsResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/grid-emissions/${tenantId}/co2-emission-factors`,
                decoder: getCo2EmissionFactorsDecoder,
                params: { page, ...(search && { search }) },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            co2EmissionFactorId: string,
        ): OverloadApiResponse<GridEmissionsDto.Co2EmissionFactor> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/grid-emissions/${tenantId}/co2-emission-factors/${co2EmissionFactorId}`,
                decoder: co2EmissionFactorDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postCo2EmissionFactorsByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            body: GridEmissionsDto.CreateCo2EmissionFactorPayload,
        ): OverloadApiResponse<GridEmissionsDto.Co2EmissionFactor> => {
            const accessToken = await getAccessToken();
            return post({
                path: `/api/grid-emissions/${tenantId}/co2-emission-factors`,
                decoder: co2EmissionFactorDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            co2EmissionFactorId: string,
            body: GridEmissionsDto.UpdateCo2EmissionFactorPayload,
        ): OverloadApiResponse<GridEmissionsDto.Co2EmissionFactor> => {
            const accessToken = await getAccessToken();
            return put({
                path: `/api/grid-emissions/${tenantId}/co2-emission-factors/${co2EmissionFactorId}`, //
                decoder: co2EmissionFactorDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        deleteCo2EmissionFactorsByCo2EmissionFactorIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            co2EmissionFactorId: string,
        ): OverloadApiResponse<undefined> => {
            const accessToken = await getAccessToken();
            return del({
                path: `/api/grid-emissions/${tenantId}/co2-emission-factors/${co2EmissionFactorId}`,
                decoder: noContentDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Feature flags
        getPublicFeatureFlags: async (getAccessToken): OverloadApiResponse<Array<string>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/grid-emissions/public/feature-flags`,
                decoder: featureFlagDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Admin
        getAdminAnnualFactors: routeBlocker,
        postAdminAnnualFactors: routeBlocker,
        putAdminAnnualFactors: routeBlocker,
        getAdminZoneDefaultFactors: routeBlocker,
        postAdminZoneDefaultFactors: routeBlocker,
        putAdminZoneDefaultFactors: routeBlocker,
        getAdminZoneDefaultFactorsDataCheck: routeBlocker,
        // getAdminYearlyIntensitiesSync: routeBlocker,
    };
}
