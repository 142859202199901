import {
    EacCodeUseTypeEnum,
    EacDataDto,
    EacProvidersEnum,
    validateStrictDecoder,
} from "@flexidao/dto";
import * as D from "schemawax";
import { dateDecoder, energySourceDecoder, siteTypeDecoder } from "./misc";

export const baseRegistryDecoder: D.Decoder<EacDataDto.BaseRegistry> = D.object({
    required: {
        registryId: D.string,
        name: D.string,
        countryIds: D.array(D.string),
        eacSchemeId: D.string,
    },
});

export const baseSiteDecoder: D.Decoder<EacDataDto.BaseSite> = D.object({
    required: {
        siteId: D.string,
        tenantId: D.string,
        name: D.string,
        siteType: siteTypeDecoder,
        countryId: D.string,
        biddingZoneId: D.string,
        timezone: D.string,
        syncVersion: dateDecoder,
    },
    optional: {
        eacCodeIds: D.array(D.number),
        energySourceId: energySourceDecoder,
        consumptionContractIds: D.array(D.string),
        productionContractIds: D.array(D.string),
    },
});

export const baseRegistriesArrayDecoder: D.Decoder<Array<EacDataDto.BaseRegistry>> =
    D.array(baseRegistryDecoder);

const countryDecoder: D.Decoder<EacDataDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
    },
});

export const countryArrayDecoder: D.Decoder<Array<EacDataDto.Country>> = D.array(countryDecoder);

const eacProviderDecoder: D.Decoder<EacProvidersEnum> = D.literalUnion(
    EacProvidersEnum.CsvUpload,
    EacProvidersEnum.Cnmc,
);

export const useTypeDecoder: D.Decoder<EacCodeUseTypeEnum> = D.literalUnion(
    EacCodeUseTypeEnum.Beneficiary,
    EacCodeUseTypeEnum.CancellationParty,
    EacCodeUseTypeEnum.ConsumptionSite,
    EacCodeUseTypeEnum.ProductionSite,
);

export const baseEacCodeDecoder: D.Decoder<EacDataDto.BaseEacCode> = D.object({
    required: {
        eacCodeId: D.number,
        eacCode: D.string,
        tenantId: D.string,
        registryId: D.string,
        initialTimeToCheck: dateDecoder,
        enabled: D.boolean,
        useType: useTypeDecoder,
    },
    optional: {
        providerId: eacProviderDecoder,
        credentialId: D.string,
    },
});

const proofOfCancellationDetailDecoder: D.Decoder<EacDataDto.ProofOfCancellationDetail> = D.object({
    required: {
        proofOfCancellationId: D.string,
        productionCountry: D.nullable(D.string),
        tenantId: D.string,
        documentId: D.number,
        executionId: D.string,
        consumptionSiteId: D.nullable(D.string),
        quantityWh: D.number,
        guaranteesIdFrom: D.nullable(D.string),
        guaranteesIdTo: D.nullable(D.string),
        registryTransactionId: D.string,
        registryTransactionTimestamp: D.nullable(dateDecoder),
        registryTransactionTimezone: D.nullable(D.string),
        registryId: D.string,
        energySourceId: energySourceDecoder,
        transactionFromName: D.nullable(D.string),
        transactionFromId: D.nullable(D.string),
        transactionToName: D.nullable(D.string),
        transactionToId: D.nullable(D.string),
        productionPeriodStart: dateDecoder,
        productionPeriodEnd: dateDecoder,
        productionPeriodTimezone: D.string,
        productionSiteId: D.string,
        productionSiteName: D.string,
        productionSiteCommissioningDate: D.nullable(dateDecoder),
        consumptionOrganizationName: D.nullable(D.string),
        consumptionOrganizationId: D.nullable(D.string),
        consumptionPeriodStart: dateDecoder,
        consumptionPeriodEnd: dateDecoder,
        consumptionPeriodTimezone: D.string,
        productionCountryName: D.nullable(D.string),
        schemeId: D.string,
        registryName: D.string,
        energySourceName: D.string,
        documentName: D.string,
        productionState: D.nullable(D.string),
    },
});

export const getProofOfCancellationsDecoder: D.Decoder<EacDataDto.GetProofOfCancellationsResponse> =
    D.object({
        required: {
            totalCount: D.number,
            proofOfCancellations: D.array(proofOfCancellationDetailDecoder),
        },
    });

const attestationDetailDecoder: D.Decoder<EacDataDto.AttestationDetail> = D.object({
    required: {
        attestationId: D.string,
        transactionId: D.string,
        tenantId: D.string,
        attestationDocumentId: D.number,
        eacSchemeId: D.nullable(D.string),
        transactionFrom: D.string,
        consumptionPeriodStart: dateDecoder,
        consumptionPeriodEnd: dateDecoder,
        consumptionTimezone: D.string,
        quantityWh: D.number,
        consumptionOrganizationName: D.nullable(D.string),
        energySourceId: D.nullable(energySourceDecoder),
        productionSiteCommissioningDate: D.nullable(dateDecoder),
        productionCountryId: D.nullable(D.string),
        productionSiteName: D.nullable(D.string),
        energySourceName: D.nullable(D.string),
        productionCountryName: D.nullable(D.string),
        executionId: D.string,
    },
});

export const getAttestationsDecoder: D.Decoder<EacDataDto.GetAttestationsResponse> = D.object({
    required: {
        totalCount: D.number,
        attestations: D.array(attestationDetailDecoder),
    },
});

export const getAttestationsFilterOptionsDecoder: D.Decoder<EacDataDto.GetAttestationsFilterOptionsResponse> =
    D.object({
        required: {
            countries: D.array(
                D.object({
                    required: {
                        countryId: D.string,
                        name: D.string,
                    },
                }),
            ),
            eacSchemes: D.array(
                D.object({
                    required: {
                        eacSchemeId: D.string,
                        name: D.string,
                    },
                }),
            ),
            energySources: D.array(
                D.object({
                    required: {
                        energySourceId: energySourceDecoder,
                        name: D.string,
                    },
                }),
            ),
        },
    });

export const proofOfCancellationTotalsDecoder: D.Decoder<EacDataDto.ProofOfCancellationTotals> =
    D.object({
        required: {
            totalCancelledMWh: D.number,
            schemeTotals: D.array(
                D.object({
                    required: {
                        eacSchemeId: D.string,
                        eacSchemeName: D.string,
                        cancelledMWh: D.number,
                        cancelledPercentage: D.number,
                    },
                }),
            ),
            energySourceTotals: D.array(
                D.object({
                    required: {
                        energySourceId: energySourceDecoder,
                        energySourceName: D.string,
                        cancelledMWh: D.number,
                        cancelledPercentage: D.number,
                    },
                }),
            ),
            commissioningDateTotals: D.array(
                D.object({
                    required: {
                        commissioningDatePeriod: D.string,
                        cancelledMWh: D.number,
                        cancelledPercentage: D.number,
                    },
                }),
            ),
            registryTotals: D.array(
                D.object({
                    required: {
                        registryId: D.string,
                        registryName: D.string,
                        cancelledMWh: D.number,
                        cancelledPercentage: D.number,
                    },
                }),
            ),
            productionCountryTotals: D.array(
                D.object({
                    required: {
                        countryId: D.string,
                        countryName: D.string,
                        cancelledMWh: D.number,
                        cancelledPercentage: D.number,
                    },
                }),
            ),
            counterpartTotals: D.array(
                D.object({
                    required: {
                        transactionFrom: D.string,
                        cancelledMWh: D.number,
                    },
                }),
            ),
        },
    });

export const baseEacCodeArrayDecoder: D.Decoder<Array<EacDataDto.BaseEacCode>> =
    D.array(baseEacCodeDecoder);

export const eacCodeWithRegistryDecoder: D.Decoder<EacDataDto.EacCodeWithRegistry> = D.object({
    required: {
        eacCodeId: D.number,
        eacCode: D.string,
        tenantId: D.string,
        registry: baseRegistryDecoder,
        initialTimeToCheck: dateDecoder,
        enabled: D.boolean,
        useType: useTypeDecoder,
    },
    optional: {
        providerId: eacProviderDecoder,
        credentialId: D.string,
    },
});

export const eacCodeWithRegistryArrayDecoder: D.Decoder<Array<EacDataDto.EacCodeWithRegistry>> =
    D.array(eacCodeWithRegistryDecoder);

const yyyymmddDecoder: D.Decoder<string> = D.string.andThen((str) => str.trim().replace("-", "/"));

const processedCertificateFromPdfDecoder: D.Decoder<EacDataDto.ProcessedCertificateFromPdf> =
    validateStrictDecoder<EacDataDto.ProcessedCertificateFromPdf>()(
        D.object({
            optional: {
                registry: D.nullable(D.string),
                productionCountry: D.nullable(D.string),
                productionState: D.nullable(D.string),
                transactionType: D.nullable(D.string),
                transactionId: D.nullable(D.string),
                transactionDate: D.nullable(D.string),
                transactionTimezone: D.nullable(D.string),
                fromCertificateId: D.nullable(D.string),
                toCertificateId: D.nullable(D.string),
                quantity: D.nullable(D.oneOf(D.string, D.number)),
                transactionFromName: D.nullable(D.string),
                transactionFromId: D.nullable(D.string),
                transactionToName: D.nullable(D.string),
                transactionToId: D.nullable(D.string),
                productionPeriodStart: D.nullable(yyyymmddDecoder),
                productionPeriodEnd: D.nullable(yyyymmddDecoder),
                productionTimezone: D.nullable(D.string),
                productionSiteName: D.nullable(D.string),
                productionSiteId: D.nullable(D.string),
                productionSiteCommissioningDate: D.nullable(yyyymmddDecoder),
                energySource: D.nullable(D.string),
                consumptionOrganizationName: D.nullable(D.string),
                consumptionOrganizationId: D.nullable(D.string),
                consumptionSiteId: D.nullable(D.string),
                consumptionPeriodStart: D.nullable(yyyymmddDecoder),
                consumptionPeriodEnd: D.nullable(yyyymmddDecoder),
                consumptionTimezone: D.nullable(D.string),
                attachedSourceDocumentName: D.nullable(D.string),
            },
        }),
    );

export const uploadEacPdfResponseDecoder: D.Decoder<EacDataDto.UploadEacPdfResponse> =
    validateStrictDecoder<EacDataDto.UploadEacPdfResponse>()(
        D.object({
            required: {
                certificates: D.array(processedCertificateFromPdfDecoder),
            },
        }),
    );
