import { round } from "@flexidao/helpers";
import { ONE_HUNDRED, ONE_MILLION, ONE_THOUSAND } from "../consts";

/***
 * Converts a value in grams to tonnes.
 * @param value The value in grams.
 * @param shouldRound Whether to round the value
 * @returns The value in tonnes.
 */
export const gramsToTonnes = (value: number, shouldRound: boolean = false): number => {
    if (shouldRound) {
        return round(value / ONE_MILLION);
    }
    return value / ONE_MILLION;
};

/***
 * Converts a value in Wh to MWh.
 * @param value The value in Wh.
 * @param shouldRound Whether to round the value
 * @returns The value in Mwh.
 */
export const whToMwh = (value: number, shouldRound: boolean = false): number => {
    if (shouldRound) {
        return round(value / ONE_MILLION);
    }
    return value / ONE_MILLION;
};

/**
 * Converts a value in g/Wh to g/kWh.
 *
 * @param value The value in g/Wh.
 * @param shouldRound Whether to round the value
 * @returns The value in g/kWh.
 */
export const gramsPerWhToGramsPerKWh = (value: number, shouldRound: boolean = false): number => {
    if (shouldRound) {
        return round(value * ONE_THOUSAND);
    }
    return value * ONE_THOUSAND;
};

/***
 * Converts a value in kWh to MWh.
 * @param value The value in kWh.
 * @param shouldRound Whether to round the value
 * @returns The value in Mwh.
 */
export const kwhToMwh = (value: number, shouldRound: boolean = false): number => {
    if (shouldRound) {
        return round(value / ONE_THOUSAND);
    }
    return value / ONE_THOUSAND;
};

/**
 * #### Converts a value in MWh to Wh.
 * ***NB:** Rounds by default as we store Wh as an int in the DB and Wh is the smallest unit we use*
 * @param value The value in MWh.
 * @param shouldRound Whether to round the value
 * @returns The value in Wh.
 */
export const mwhToWh = (value: number, shouldRound: boolean = true): number =>
    shouldRound ? round(value * ONE_MILLION) : value * ONE_MILLION;

/**
 * Converts number to percent
 * @param value The decimal value to convert to percent
 * @param shouldRound Whether to round the value
 * @returns  The percent value
 */
export const decimalToPercent = (value: number, shouldRound: boolean = false): number => {
    if (shouldRound) {
        return round(value * ONE_HUNDRED);
    }
    return value * ONE_HUNDRED;
};

/**
 *
 * @param value The value to halve
 * @returns half the value
 */
// eslint-disable-next-line no-magic-numbers
export const halve = (value: number): number => value / 2;

/**
 * @param monthNumber number 1 - 12
 * @returns short name of the month .e.g. `1=Jan`
 */
export const getShortMonthName = (monthNumber: number): string =>
    // eslint-disable-next-line no-magic-numbers
    new Date(2000, monthNumber - 1)
        .toLocaleString("en-GB", { month: "short" })
        .replace("Sept", "Sep");

/**
 * Converts a ratio to a percentage
 * @param value The ratio to convert to a percentage
 * @param options Options for the conversion
 * @returns The percentage value
 */
export const ratioToPercentage = (
    value: number,
    options?: {
        shouldRound?: boolean;
        maxPercentage?: number;
    },
): number => {
    // If the value is not a number, return 0
    if (isNaN(value)) {
        return 0;
    }

    const percentage: number = value * ONE_HUNDRED;

    // Constrain the percentage value to the max percentage if the option is provided
    const percentageWithMax: number =
        options?.maxPercentage != null //
            ? Math.min(percentage, options.maxPercentage)
            : percentage;

    // Round the percentage if the option is provided
    const roundedPercentage: number = options?.shouldRound
        ? round(percentageWithMax)
        : percentageWithMax;

    return roundedPercentage;
};

/**
 * Converts bytes to megabytes
 *
 * @param bytes - The number of bytes
 * @param shouldRound - Whether to round the value to the nearest integer
 *
 * @returns The value in megabytes
 */
export const bytesToMegabytes = (bytes: number, shouldRound: boolean = false): number => {
    const megabytes: number = bytes / ONE_MILLION;

    return shouldRound ? round(megabytes) : megabytes;
};
