import { formatDate, formatDateMonth, formatDateTime } from "./formatters/format-date";
import { Interval } from "luxon";

export const getDateFormatterForPeriod = ([startDate, endDate]: [Date, Date]): ((
    date: Date,
    timezone?: string,
) => string) => {
    const duration = Interval.fromDateTimes(startDate, endDate).toDuration();

    let dateFormatter;
    // eslint-disable-next-line no-magic-numbers -- It is not a magic numbers as we are comparing with weeks.
    if (duration.weeks <= 4) {
        dateFormatter = formatDateTime;
    } else if (duration.years <= 1) {
        dateFormatter = formatDate;
    } else {
        dateFormatter = formatDateMonth;
    }
    return dateFormatter;
};
