import { ContractType } from "@flexidao/dto";

export const formatContractType = (contractType: ContractType | null): string => {
    switch (contractType) {
        case ContractType.VPPA:
            return "V-PPA";
        case ContractType.PPA:
            return "PPA";
        case ContractType.DirectPPA:
            return "Direct PPA";
        case ContractType.GreenTariff:
            return "Green Tariff";
        case ContractType.UnbundledEACs:
            return "Unbundled EACs";
        case null:
            return "Other";
        default:
            return "-";
    }
};
