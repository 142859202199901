import {
    Button,
    CSSObject,
    Divider,
    Group,
    MantineTheme,
    Paper,
    Popover,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { ReactElement, useEffect, useState } from "react";
import { IconChevronDown } from "@tabler/icons-react";
import { labelToDataId } from "@flexidao/ui-lib";

type FilterOption<T extends string> = {
    label: string;
    value: T;
};
export type FilterSection<T extends string> = {
    sectionLabel: string;
    options: Array<FilterOption<T>>;
};
type SelectWithSectionsFilterProps<T extends string> = {
    title: string;
    data: Array<FilterSection<T>>;
    disabled?: boolean;
    value: T | null;
    setValue: (value: T) => void;
    dataIdPrefix: string;
    noDataLabel: string;
    icon?: ReactElement;
};

export const SelectFilterWithSections = <T extends string>({
    title,
    data,
    disabled = false,
    dataIdPrefix,
    value,
    setValue,
    noDataLabel,
    icon = <IconChevronDown size={16} />,
}: SelectWithSectionsFilterProps<T>): ReactElement => {
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [provisionalValue, setProvisionalValue] = useState<T | null>(value ?? null);

    // Value is changed from outside,as first value is selected by default. We need to update provisional value,
    // as it is used to display isSelected value in dropdown
    useEffect(() => {
        setProvisionalValue(value);
    }, [value]);

    const confirmSelection = (): void => {
        if (provisionalValue === null) {
            return;
        }
        setValue(provisionalValue);
        setFilterOpen(false);
    };

    const allOptions: Array<FilterOption<T>> = data.flatMap((section) => section.options);

    const selectedLabel =
        allOptions.length === 0
            ? noDataLabel
            : value
              ? allOptions.find((item) => item.value === value)?.label
              : "Select";
    return (
        <Popover
            opened={filterOpen}
            onChange={(opened: boolean): void => setFilterOpen(opened)}
            position="bottom-start"
            closeOnClickOutside
            closeOnEscape
        >
            <Popover.Target>
                <Paper
                    onClick={
                        disabled
                            ? undefined
                            : (): void => {
                                  setFilterOpen(!filterOpen);
                              }
                    }
                    px={12}
                    py={4}
                    bg={disabled ? "flexidaoGrey.1" : "white"}
                    sx={{
                        cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "target",
                    })}
                >
                    <Group position="apart" align="center">
                        <Stack spacing={2}>
                            <Title fz="12px" lh="14px" fw={600}>
                                {title}
                            </Title>
                            <Text
                                truncate
                                fz="12px"
                                lh="14px"
                                size="sm"
                                c="flexidaoGrey.8"
                                maw="318px"
                            >
                                {selectedLabel}
                            </Text>
                        </Stack>

                        {icon}
                    </Group>
                </Paper>
            </Popover.Target>

            <Popover.Dropdown
                p={0}
                sx={(theme: MantineTheme): CSSObject => ({
                    border: "none",
                    borderRadius: theme.radius.md,
                })}
            >
                <Paper
                    p={0}
                    sx={{
                        minWidth: "250px",
                        maxWidth: "400px",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "dropdown",
                    })}
                >
                    <Stack
                        spacing="4px"
                        sx={{
                            maxHeight: "300px",
                            overflowY: "auto",
                        }}
                        p="0.5rem"
                    >
                        {data.map((section) => {
                            return (
                                <Stack key={section.sectionLabel} spacing={0}>
                                    <Divider
                                        label={section.sectionLabel}
                                        labelPosition="left"
                                        data-id={labelToDataId({
                                            prefix: `${dataIdPrefix}-section-label`,
                                            label: `${section.sectionLabel}`,
                                        })}
                                        c={"flexidaoGrey.6"}
                                    />
                                    {section.options.map((item) => {
                                        const isSelected = provisionalValue === item.value;
                                        return (
                                            <Text
                                                py="0.5rem"
                                                px="md"
                                                key={item.value}
                                                data-id={labelToDataId({
                                                    prefix: `${dataIdPrefix}-select-item`,
                                                    label: `${item.value}`,
                                                })}
                                                onClick={(): void => {
                                                    setProvisionalValue(item.value);
                                                }}
                                                sx={(theme): CSSObject => ({
                                                    "&:hover": {
                                                        backgroundColor: isSelected
                                                            ? "none"
                                                            : theme.colors.green[0],
                                                        cursor: "pointer",
                                                        borderRadius: "0.5rem",
                                                    },
                                                    background: isSelected
                                                        ? theme.colors.green[5]
                                                        : "none",
                                                    borderRadius: "0.5rem",
                                                    color: isSelected ? theme.white : theme.black,
                                                    fontWeight: isSelected ? "bold" : "normal",
                                                    fontSize: "0.875rem",
                                                    lineHeight: "1.25rem",
                                                })}
                                            >
                                                {item.label}
                                            </Text>
                                        );
                                    })}
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Divider />

                    <Group p="md" position="right">
                        <Button
                            data-id={labelToDataId({
                                prefix: dataIdPrefix,
                                label: "confirm-button",
                            })}
                            disabled={!provisionalValue || provisionalValue === value}
                            onClick={(): void => {
                                confirmSelection();
                            }}
                            uppercase
                        >
                            Apply Selection
                        </Button>
                    </Group>
                </Paper>
            </Popover.Dropdown>
        </Popover>
    );
};
