import { DAY_MS, ONE_WEEK_MS } from "../consts";

export const getCurrentYear = (): number => new Date().getFullYear();
const firstMonthOfTheYear = 0;
const lastMonthOfTheYear = 11;
const firstDayOfJanuary = 1;
const lastDayOfDecember = 31;

export const getFirstDayOfCurrentYear = (): Date =>
    new Date(getCurrentYear(), firstMonthOfTheYear, firstDayOfJanuary, 0, 0, 0, 0);

export const getFirstDayOfCurrentYearLocal = (): Date =>
    new Date(Date.UTC(getCurrentYear(), firstMonthOfTheYear, firstDayOfJanuary, 0, 0, 0, 0));

export const getFirstDayOfLastYear = (): Date =>
    new Date(getCurrentYear() - 1, firstMonthOfTheYear, firstDayOfJanuary, 0, 0, 0, 0);

export const getLastDayOfLastYear = (): Date =>
    new Date(getCurrentYear() - 1, lastMonthOfTheYear, lastDayOfDecember, 0, 0, 0, 0);

export const getCurrentDate = (): Date => {
    const currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return currentDate;
};

export const get7DaysAgo = (): Date => {
    const currentDate: Date = getCurrentDate();

    // Add 1 extra day to get the last 7 days, because the current day is included in the range
    const sevenDaysAgo: Date = new Date(currentDate.getTime() - ONE_WEEK_MS + DAY_MS);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    return sevenDaysAgo;
};

export const getStartOfYearLocal = (year: number): Date =>
    new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0));

export const getStartOfCurrentYearLocal = (): Date => {
    const now = new Date();
    return new Date(Date.UTC(now.getFullYear(), 0, 1));
};
export const getCurrentMonthLocal = (): Date => {
    const now = new Date();
    return new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
};

export const getNextMonthLocal = (): Date => {
    const now = new Date();
    return new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));
};

export const getLastDayOfYearLocal = (year: number): Date =>
    new Date(Date.UTC(year, lastMonthOfTheYear, lastDayOfDecember, 0, 0, 0, 0));

/*
 * @description Removes the offset from the Date object
 * @param date The date we want to remove the offset from
 * @example
 *  input: 2024-01-01T00:00:00+02:00
 *  output: 2024-01-01T00:00:00+00:00
 * */
export const removeOffsetFromDate = (date: Date): Date =>
    new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
