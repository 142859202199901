import { ReactElement, useMemo } from "react";
import { Group, List, MantineTheme, Stack, Text, useMantineTheme } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Dropzone, FileRejection, FileWithPath } from "@mantine/dropzone";
import { IconFileText, IconUpload, IconX } from "@tabler/icons-react";
import { AcceptedFileType } from "./types";
import { getDropzoneDescription, getMimeTypesFromAcceptedFileType } from "./utils";

type FileDropZoneProps = {
    maxFiles?: number;
    maxSizeBytes?: number;
    acceptedFileTypes?: Array<AcceptedFileType>;
    onFileDropped: (files: Array<FileWithPath>) => void;
    disabled?: boolean;
};

export const FileDropZone = ({
    maxFiles,
    maxSizeBytes,
    acceptedFileTypes,
    onFileDropped,
    disabled,
}: FileDropZoneProps): ReactElement => {
    const theme: MantineTheme = useMantineTheme();

    const acceptedMimeTypes: Array<string> | undefined = useMemo(
        () => acceptedFileTypes?.flatMap(getMimeTypesFromAcceptedFileType) ?? undefined,
        [acceptedFileTypes],
    );

    const description: string = useMemo(
        () => getDropzoneDescription({ maxFiles, maxSizeBytes }),
        [maxFiles, maxSizeBytes],
    );

    return (
        <Dropzone
            // Action props
            onDrop={onFileDropped}
            onReject={(fileRejections: Array<FileRejection>): void => {
                fileRejections.forEach(({ file, errors }) =>
                    notifications.show({
                        title: `Error while processing file ${file.name}`,
                        message: (
                            <List>
                                {errors.map(({ message }) => (
                                    <List.Item key={message}>{message}</List.Item>
                                ))}
                            </List>
                        ),
                        color: "flexidaoRed",
                    }),
                );
            }}
            disabled={disabled}
            // ----------------
            // Validation props
            multiple={maxFiles !== undefined && maxFiles > 1}
            maxFiles={maxFiles}
            maxSize={maxSizeBytes}
            accept={acceptedMimeTypes}
            // ----------------
            // UX props
            activateOnClick
            activateOnDrag
            activateOnKeyboard
        >
            <Group
                position="center"
                spacing="xl"
                style={{
                    minHeight: "220px",
                    pointerEvents: "none",
                }}
            >
                <Dropzone.Idle>
                    <IconFileText size="3.2rem" stroke={1.5} />
                </Dropzone.Idle>

                <Dropzone.Accept>
                    <IconUpload size="3.2rem" stroke={1.5} color={theme.colors.flexidaoGreen[5]} />
                </Dropzone.Accept>

                <Dropzone.Reject>
                    <IconX size="3.2rem" stroke={1.5} color={theme.colors.flexidaoRed[5]} />
                </Dropzone.Reject>

                <Stack>
                    <Text size="xl" inline>
                        Drag your files here or click to select files
                    </Text>

                    <Text size="sm" color="dimmed" inline>
                        {description}
                    </Text>
                </Stack>
            </Group>
        </Dropzone>
    );
};
