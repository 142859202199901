import { formatNumber } from "@flexidao/ui-lib";
import { Group, Skeleton, Text } from "@mantine/core";
import { ReactElement } from "react";
import { ComponentState } from "@flexidao/dto";
import { SKELETON_SMALL_VALUE_DEFAULT_WIDTH } from "./consts";

export type KpiSectionSmallProps = {
    label: string;
    value: number | null;
    numDecimals: number;
    unit: string | null;
    dataId?: string;
    showSkeleton?: boolean; // This will be removed later on
    state?: ComponentState; // This will be made mandatory later on
    color?: string;
};

export const KpiSectionSmall = ({
    label,
    value,
    numDecimals,
    unit,
    dataId,
    showSkeleton,
    state,
    color,
}: KpiSectionSmallProps): ReactElement => (
    <Group position="apart" align="flex-start" px="xl" py={8} mih={40} data-id={dataId}>
        <Text size="sm" weight={600}>
            {label}
        </Text>

        {getDisplayValue({ showSkeleton, state, value, numDecimals, unit, color })}
    </Group>
);

const getDisplayValue = ({
    showSkeleton,
    state,
    value,
    numDecimals,
    unit,
    color,
}: {
    showSkeleton?: boolean;
    state?: ComponentState;
    value: number | null;
    numDecimals: number;
    unit: string | null;
    color?: string;
}): ReactElement => {
    if (showSkeleton || state === ComponentState.Loading) {
        return <Skeleton height={21} width={SKELETON_SMALL_VALUE_DEFAULT_WIDTH} />;
    }

    if (state === ComponentState.Error) {
        return (
            <Text size="xs" color="flexidaoRed" ta="right">
                Error
            </Text>
        );
    }

    const valueString: string = formatNumber(value, numDecimals);

    return (
        <Text size="sm" weight={600} ta="right" color={color}>
            {valueString} {value !== null && unit !== null && unit}
        </Text>
    );
};
