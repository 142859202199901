export type WithTimestamps<Dto> = Dto & {
    updated: Date;
    deleted: Date | null;
};

export type AbsentOptional<T> = null | undefined extends T
    ? null | undefined
    : null extends T
      ? null
      : undefined extends T
        ? undefined
        : T extends Array<infer U>
          ? Array<AbsentOptional<U>>
          : T extends object
            ? { [K in keyof T]: AbsentOptional<T[K]> }
            : T;

export const crash = <T>(msg: string): T => {
    throw new Error(msg);
};
