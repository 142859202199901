import { Module, UploadsPage } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath = `/${APP_PREFIX}/${Module.Uploads}`;

type UploadsPaths = {
    [UploadsPage.EacUploads]: string;
};

export const uploadsPaths: UploadsPaths = {
    [UploadsPage.EacUploads]: `${basePath}/${UploadsPage.EacUploads}`,
};
