import { AcceptedFileType } from "./types";
import { MIME_TYPES, PDF_MIME_TYPE } from "@mantine/dropzone";
import { bytesToMegabytes, MEGABYTES_NUM_DECIMALS } from "@flexidao/ui-lib";

export const getMimeTypesFromAcceptedFileType = (
    acceptedFileTypes: AcceptedFileType,
): Array<string> => {
    switch (acceptedFileTypes) {
        case AcceptedFileType.Pdf:
            return PDF_MIME_TYPE;
        case AcceptedFileType.Csv:
            return [MIME_TYPES.csv];
    }
};

export const getDropzoneDescription = ({
    maxFiles,
    maxSizeBytes,
}: {
    maxFiles?: number;
    maxSizeBytes?: number;
}): string => {
    if (maxFiles && maxSizeBytes) {
        return `You can upload up to ${maxFiles} file${maxFiles > 1 ? "s" : ""} with a maximum size of ${bytesToMegabytes(
            maxSizeBytes,
        ).toFixed(MEGABYTES_NUM_DECIMALS)} MB`;
    }

    if (maxFiles && !maxSizeBytes) {
        return `You can upload up to ${maxFiles} file${maxFiles > 1 ? "s" : ""}`;
    }

    if (!maxFiles && maxSizeBytes) {
        return `You can upload multiple files with a maximum size of ${bytesToMegabytes(maxSizeBytes).toFixed(MEGABYTES_NUM_DECIMALS)} MB`;
    }

    return "You can upload multiple files";
};
